import { Middleware, SWRHook } from "swr";

const unauthorizedMiddleware: Middleware =
  (useSWRNext: SWRHook) => (key, fetcher, config) => {
    // 包装原始的 fetcher
    const wrappedFetcher = async (...args: any[]) => {
      try {
        const response = await (fetcher as any)(...args);
        if (response.errorCode === "401001") {
          window.location.href = "/login";
        }
        return response;
      } catch (error: any) {
        // 检查是否是 401 错误
        if (error?.status === 401 || error?.response?.status === 401) {
          // 重定向到登录页面
          window.location.href = "/login";
        }
        throw error;
      }
    };

    // 使用包装后的 fetcher
    return useSWRNext(key, fetcher ? wrappedFetcher : null, config);
  };

export default unauthorizedMiddleware;
