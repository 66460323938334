"use client";

import unauthorizedMiddleware from "@/lib/swr/unauthorizedMiddleware";
import { NextUIProvider } from "@nextui-org/react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { SWRConfig } from "swr";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <SWRConfig value={{ use: [unauthorizedMiddleware] }}>
      <NextUIProvider>
        {children}
        <ProgressBar
          height="4px"
          color="#3fcf8e"
          options={{ showSpinner: false }}
          shallowRouting
        />
      </NextUIProvider>
    </SWRConfig>
  );
}
