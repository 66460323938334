import { create } from "zustand";
import { Plan } from "../shared/payment/constants";
import {
  ResourceRuleType,
  ResourceType,
  ResourceUsageRule,
} from "../shared/resource-usage/constants";
import { TSubscription } from "./subscription";

export type TResourceUsage = {
  id: string;
  userId: string;
  resource: string;
  usageCount: number;
  createdAt: Date;
  updatedAt: Date;
};

type state = {
  resourceUsage?: TResourceUsage[];
  subscription?: TSubscription;
};

type actions = {
  setResourceUsage: (resourceUsage: TResourceUsage[]) => void;
  setSubscription: (subscription: TSubscription) => void;
  checkResourceReachedLimit: (
    resource: ResourceType,
    additionalValue?: number,
  ) => boolean;
  fetchResourceUsage: () => Promise<void>;
  fetchSubscription: () => Promise<void>;
  checkIsSubscription: () => boolean;
};

const useResourceUsage = create<state & actions>()((set, get) => ({
  setResourceUsage: (resourceUsage) => set({ resourceUsage }),
  setSubscription: (subscription) => set({ subscription }),
  checkResourceReachedLimit: (resource, additionalValue) => {
    const subscription = get().subscription;
    const resourceUsage = get().resourceUsage;

    const plan = subscription ? subscription.plan : Plan.Free;

    if (plan !== Plan.Free) {
      return false;
    }

    const resourceUsageRule = ResourceUsageRule[plan as Plan];
    const usage = resourceUsageRule[resource as ResourceType];

    if (!usage) return false;

    if (
      additionalValue !== undefined &&
      usage.type === ResourceRuleType.QUOTA
    ) {
      return additionalValue >= (usage.value as number);
    }

    if (!resourceUsage) return false;

    const currentRuleUsage = resourceUsage.find((i) => i.resource === resource);

    if (currentRuleUsage && usage.type === ResourceRuleType.QUOTA) {
      return currentRuleUsage.usageCount >= (usage.value as number);
    }

    if (usage.type === ResourceRuleType.ENABLE) {
      return !usage.value as boolean;
    }

    return false;
  },
  fetchResourceUsage: async () => {
    const response = await fetch("/api/payment/resource-usage");
    const data = await response.json();
    set({ resourceUsage: data.data });
  },
  fetchSubscription: async () => {
    const response = await fetch("/api/user/subscription");
    const data = await response.json();
    set({ subscription: data.data });
  },
  checkIsSubscription: () => {
    const subscription = get().subscription;
    return subscription ? true : false;
  },
}));

export default useResourceUsage;
