"use client";

import { useSearchParams } from "next/navigation";
import { useEffect } from "react";

export const useUtm = () => {
  const searchParams = useSearchParams();

  useEffect(() => {
    const params: Record<string, string> = {};
    const utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];

    utmKeys.forEach((key) => {
      const value = searchParams.get(key);
      if (value) {
        const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
          letter.toUpperCase(),
        );
        params[camelCaseKey] = value;
      }
    });

    const storedParams = localStorage.getItem("utm_params");

    if (!storedParams && Object.keys(params).length > 0) {
      params.landingUrl = window.location.href;
      params.referrer = document.referrer;
      params.firstVisitAt = new Date().toISOString();
      localStorage.setItem("utm_params", JSON.stringify(params));
    }
  }, [searchParams]);

  const sendUtmParamsToServer = async () => {
    const isReported = localStorage.getItem("isReported");
    if (isReported) {
      return;
    }
    const utmParams = JSON.parse(localStorage.getItem("utm_params") || "{}");
    await fetch("/api/user-trackings", {
      method: "POST",
      body: JSON.stringify(utmParams),
    }).catch((error) => {
      console.error(error);
    });
    localStorage.setItem("isReported", "true");
  };

  return { sendUtmParamsToServer };
};
