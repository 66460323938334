import { Plan } from "../payment/constants";

export enum ResourceType {
  ResumeCreate = "resumeCreate",
  ResumeDownload = "resumeDownload",
  ResumeImport = "resumeImport",
  AIResumeOptimize = "aiResumeOptimize",
  AIResumeGeneration = "aiResumeGeneration",
  AIResumeAnalysis = "aiResumeAnalysis",
  AIResumeInterviewAssistant = "aiResumeInterviewAssistant",
  AIResumeCareerPlanning = "aiResumeCareerPlanning",
  AIResumeMockInterview = "aiResumeMockInterview",
  AIResumeTranslation = "aiResumeTranslation",
}

export enum ResourceRuleType {
  QUOTA = "QUOTA",
  VALUE = "VALUE",
  ENABLE = "ENABLE",
}

enum RuleCycleType {
  NATURAL_DAY = "NATURAL_DAY",
  NATURAL_MONTH = "NATURAL_MONTH",
}

export type ResourceUsage = {
  type: ResourceRuleType;
  cycleType?: RuleCycleType;
  value: number;
};

export const ResourceUsageRule: {
  [key in Plan]: {
    [key in ResourceType]: ResourceUsage;
  };
} = {
  [Plan.Free]: {
    [ResourceType.ResumeCreate]: {
      type: ResourceRuleType.QUOTA,
      value: 2,
    },
    [ResourceType.ResumeDownload]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_DAY,
      value: 1,
    },
    [ResourceType.ResumeImport]: {
      type: ResourceRuleType.QUOTA,
      value: 5,
    },
    [ResourceType.AIResumeGeneration]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: 5,
    },
    [ResourceType.AIResumeOptimize]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: 5,
    },
    [ResourceType.AIResumeAnalysis]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: 5,
    },
    [ResourceType.AIResumeInterviewAssistant]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: 5,
    },
    [ResourceType.AIResumeCareerPlanning]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: 5,
    },
    [ResourceType.AIResumeMockInterview]: {
      type: ResourceRuleType.QUOTA,
      value: 20,
    },
    [ResourceType.AIResumeTranslation]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: 1,
    },
  },
  [Plan.Plus]: {
    [ResourceType.ResumeCreate]: {
      type: ResourceRuleType.QUOTA,
      value: Infinity,
    },
    [ResourceType.ResumeDownload]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_DAY,
      value: Infinity,
    },
    [ResourceType.ResumeImport]: {
      type: ResourceRuleType.QUOTA,
      value: Infinity,
    },
    [ResourceType.AIResumeGeneration]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: Infinity,
    },
    [ResourceType.AIResumeOptimize]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: Infinity,
    },
    [ResourceType.AIResumeAnalysis]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: Infinity,
    },
    [ResourceType.AIResumeInterviewAssistant]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: Infinity,
    },
    [ResourceType.AIResumeCareerPlanning]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: Infinity,
    },
    [ResourceType.AIResumeMockInterview]: {
      type: ResourceRuleType.QUOTA,
      value: Infinity,
    },
    [ResourceType.AIResumeTranslation]: {
      type: ResourceRuleType.QUOTA,
      cycleType: RuleCycleType.NATURAL_MONTH,
      value: Infinity,
    },
  },
};
