"use client";

import { useUtm } from "@/lib/hooks/useUtm";

const UtmTracking = () => {
  useUtm();
  return <></>;
};

export default UtmTracking;
